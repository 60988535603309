<template>
  <div class="GamesView">
    <div class="games">
      <div
        v-for="game of games"
        :key="game.path"
        class="Game">
        <div class="Game__header">
          {{ game.name }}
        </div>
        <div class="Game__body">
          <div class="Game__standardEntries">
            <div
              class="Button"
              @click="gotoGame(game.gameId)">
              Enter Game
            </div>
            <div
              v-for="level of 12"
              :key="level"
              class="Button"
              @click="gotoGameLevel(game, level - 1)">
              {{ levelLabels[level - 1] }}
            </div>
          </div>

          <template v-if="game.customSessions">
            <div>Custom entries</div>
            <div
              v-for="(custom, i) of game.customSessions"
              :key="i"
              class="Custom"
              @click="startCustomSession(game, custom.settings)">
              <div class="Custom__label">
                {{ custom.label }}
              </div>
              <div
                class="Custom__toggleJsonButton"
                @click.stop="custom.expanded = !custom.expanded">
                toggle
              </div>
              <div
                v-if="custom.expanded"
                class="Custom__json">
                {{ JSON.stringify(custom.settings, null, '  ') }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gameHelper from '@/modules/games-shared/game-helper';

export default {
    data() {
        return {
            levelLabels: ['1.1', '1.2', '2.1', '2.2', '2.3', '3.1', '3.2', '3.3', '4.1', '4.2', '4.3', '4.4'],
            games: [
                {
                    name: 'Faces',
                    gameId: 'faces',
                    path: '/games/faces',
                    customSessions: [
                        {
                            label: 'practice names: Ole, Dole, Dolly',
                            expanded: false,
                            settings: {
                                numberOfItems: 3,
                                includeName: true,
                                items: [
                                    { name: 'Ole', gender: 'M', country: 'Western' },
                                    { name: 'Dole', gender: 'M', country: 'Western' },
                                    { name: 'Dolly', gender: 'F', country: 'Western' }
                                ],
                                itemMemorizeTimeLimit: 1000,
                                itemQuizTimeLimit: 1000
                            }
                        },
                        {
                            label: 'name + surname + hobby + profession',
                            expanded: false,
                            settings: {
                                numberOfItems: 2,
                                includeName: true,
                                includeSurname: true,
                                includeProfession: true,
                                includeHobby: true,
                                itemMemorizeTimeLimit: 1000,
                                itemQuizTimeLimit: 1000,
                                countryOptions: [{ country: 'Western', fraction: 1 }]
                            }
                        }
                    ]
                },
                {
                    name: 'Bankvault',
                    gameId: 'bankvault',
                    path: '/games/bankvault',
                    customSessions: [
                        {
                            label: 'restricted to only use [1,2,5,8]',
                            expanded: false,
                            settings: {
                                chunks: 4,
                                itemMemorizeTimeLimit: 1112,
                                itemQuizTimeLimit: 1115,
                                ring1: {
                                    restrict: [1, 2, 5, 8]
                                }
                            }
                        }
                    ]
                },
                {
                    name: 'Shopping',
                    gameId: 'shopping',
                    path: '/games/shopping',
                    customSessions: [
                        {
                            label: 'two items',
                            expanded: false,
                            settings: {
                                numberOfItems: 2,
                                numberOfFakeItems: 1,
                                includeQuantity: true,
                                includePrice: false,
                                itemMemorizeTimeLimit: 1000,
                                itemQuizTimeLimit: 1000
                            }
                        },
                        {
                            label: 'two items, price and quantity',
                            expanded: false,
                            settings: {
                                numberOfItems: 2,
                                numberOfFakeItems: 0,
                                includeQuantity: true,
                                includePrice: true,
                                itemMemorizeTimeLimit: 1000,
                                itemQuizTimeLimit: 1000
                            }
                        },
                        {
                            label: 'single card mode',
                            settings: {
                                numberOfItems: 3,
                                itemMemorizeTimeLimit: 1000,
                                itemQuizTimeLimit: 1110,
                                memorizeSingleCardMode: true,
                                quizShowQuestionsSequentially: true,
                                quizRandomizeQuestionOrder: true,
                                quizShowNumbershapeHints: true,
                                quizDropQuestionOnFail: true,
                                disableLives: true
                            }
                        }
                    ]
                },
                {
                    name: 'Timemachine',
                    gameId: 'timemachine',
                    path: '/games/timemachine',
                    customSessions: [
                        {
                            label: '2 questions, year only',
                            expanded: false,
                            settings: {
                                numberOfItems: 2,
                                includeYear: true,
                                itemMemorizeTimeLimit: 48,
                                itemQuizTimeLimit: 48
                            }
                        }
                    ]
                },
                {
                    name: 'Brainstorm',
                    gameId: 'brainstorm',
                    path: '/games/brainstorm',
                    customSessions: [
                        {
                            label: '2 questions, with color',
                            expanded: false,
                            settings: {
                                numberOfItems: 2,
                                difficult: 0,
                                colors: 6,
                                itemMemorizeTimeLimit: 100,
                                itemQuizTimeLimit: 100
                            }
                        }
                    ]
                },
                {
                    name: 'Vocabguru',
                    gameId: 'vocabguru',
                    path: '/games/vocabguru',
                    customSessions: [
                        {
                            label: 'two items with nadsat',
                            expanded: false,
                            settings: {
                                numberOfItems: 2,
                                itemMemorizeTimeLimit: 10,
                                itemQuizTimeLimit: 10,
                                language: 'nadsat'
                            }
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        gotoGame(gameId) {
            this.$router.replace({
                name: 'MemogeniusGame-Levels',
                params: {
                    gameId
                }
            });
        },
        gotoGameLevel(game, level) {
            this.$router.replace({
                name: 'MemogeniusGame-LevelSession',
                params: {
                    gameId: game.gameId,
                    levelIndex: level
                }
            });
        },
        startCustomSession(game, settings) {
            gameHelper.createPracticeSession({
                gameId: game.gameId,
                exitUrl: '/games',
                sessionOptions: settings,
                navigate: true
            });
        }
    }
};
</script>

<style scoped lang="scss">
.GamesView {
    padding: 1em;
    background-color: black;
    color: white;
    overflow-y: auto;
}
.Game {
    margin: 1em auto;
    // padding: 1em;
    border: 1px solid black;
    background-color: rgba(white, 0.2);
    max-width: 30em;
}
.Game__header {
    text-align: center;
    border-bottom: 1px solid rgba(black, 0.1);
    padding: 1em;
}
.Game__body {
    padding: 1em;
}

.Game__standardEntries {
    display: flex;
    flex-wrap: wrap;

    .Button {
        margin: 0.2em;
    }
    .Button:not(:first-child) {
        // margin-left: 1em;
    }
}

.Button {
    // border: 1px solid rgba(white, 0.1);
    background-color: white;
    color: black;
    border-radius: 2em;
    padding: 0.3em 1em;
}

.Custom {
    position: relative;
    padding: 1em;
    background-color: rgba(white, 0.1);
    margin: 1em;
}
.Custom__label {
    //
}
.Custom__json {
    white-space: pre;
}
.Custom__toggleJsonButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    color: yellow;
}
</style>
